<template>
    <div class="pa-5 ma-3 white rounded-lg">
        <v-row>
            <v-col cols="8">
                <div class="subheading grey--text mb-9">Customer List</div>
            </v-col>
            <v-col cols="4" class="text-right">
                <v-btn depressed color="grey darken-4" class="white--text" to="/customers/new">
                    <v-icon left>mdi-plus</v-icon>
                    New
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" offset="6" class="pt-0">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        
        <v-data-table :headers="headers" :items="items" :footer-props="paginationOptions" :search="search"
            item-key="id" :options="pagination" :loading="loading" @click:row="toCustomerDetails" @pagination="onPagination">
        </v-data-table>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        headers: [
            { text: 'Name', value: 'fullName', sortable: true },
            { text: 'Phone', value: 'Phone', sortable: true },
            { text: 'Email', value: 'email', sortable: true },
            { text: 'Date Created', value: 'dateCreated', sortable: true },
        ],
        pagination: {
            sortBy: ['fullName']
        },
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        loading: false,
        search: '',
    }),
    computed: {
        ...mapGetters ({
            items       : 'customers/customers',
            needRefresh : 'customers/needRefresh',
            hasMore     : 'customers/hasMore',
        })
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadCustomers   : 'customers/loadCustomers'
        }),
        async init() {
            try {
                if( !this.needRefresh )
                    return; 

                this.loading = true;
                await this.loadCustomers();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
        },
        toCustomerDetails( customer ) {
            this.$router.push(`/customers/${customer.id}`);
        },
        onPagination( v ) {
            if( v.pageCount != v.page || this.search != '' ) {
                return;
            }

            this.loadMore();
        },
        async loadMore() {
            try {
                if( !this.hasMore )
                    return;
                
                this.loading = true;
                await this.loadCustomers();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>